import axios from '@axios'

const PREFIX = '/acessos'

export default {

  criarTenant(novoTenant) {
    return axios.post(`${PREFIX}/tenant`, novoTenant)
  },

  getDataServer() {
    return axios.get(`${PREFIX}/data-server`)
  },

  getTenantValidate(novoTenant) {
    return axios.post(`${PREFIX}/validate-tenant`, novoTenant)
  },

  getPlanos() {
    return axios.get(`${PREFIX}/planos`)
  },
}
