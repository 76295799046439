<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            style="width: 200px;"
            :src="require('@/assets/images/logo/logo.png')"
          />
        </b-link>

        <b-card-title class="mb-1">
          Bem vindo! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Preencha os campos abaixo e comece a facilitar sua vida.
        </b-card-text>

        <validation-observer
          ref="validacao"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <b-form-group
              label-for="cnpj"
              label="CNPJ/CPF"
            >
              <validation-provider
                #default="{ errors }"
                name="CNPJ/CPF"
                :rules="'required|' + validacao"
              >
                <masked-input
                  v-model="form.cadastroFederal"
                  placeholder="CNPJ ou CPF para cadastro"
                  :class="{'is-invalid' : errors.length > 0}"
                  :mask="mascaraCadFederal"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="empresa"
              label="Nome da empresa"
            >
              <validation-provider
                #default="{ errors }"
                name="Empresa"
                rules="required"
              >
                <b-form-input
                  id="empresa"
                  v-model="form.empresa"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nome da sua empresa"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="PlanosEmpresariais"
              label="Plano empresarial"
            >
              <validation-provider
                #default="{ errors }"
                name="Plano empresarial"
                rules="numberRequired"
              >
                <b-form-select
                  v-model="form.tipoPlano"
                  :options="planos"
                  :state="errors.length > 0 ? false : null"
                  autofocus
                  @change="setDefaultQtdContatada"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="QtdCnpjs"
              label="Quantidades de CNPJs/CPFs contratados"
            >
              <validation-provider
                #default="{ errors }"
                name="Quantidade"
                rules="numberRequired"
              >
                <b-form-input
                  v-model="form.qtdContratada"
                  type="number"
                  placeholder="-"
                  :state="errors.length > 0 ? false : null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="nomeusuario"
              label="Nome do usuário"
            >
              <validation-provider
                #default="{ errors }"
                name="Usuário"
                rules="required"
              >
                <b-form-input
                  id="nomeusuario"
                  v-model="form.nomeusuario"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nome completo"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="loginInput"
              label="E-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="loginInput"
                  v-model="form.login"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="pessoa@exemplo.com.br"
                  autofocus
                  @input="form.loginSuporte = `acesso${form.login}`"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="loginSuporteInput"
              label="E-mail Usuário Suporte Interno"
            >
              <b-form-input
                id="loginSuporteInput"
                v-model="form.loginSuporte"
                name="login-email"
                disabled
                placeholder="suporteinterno@exemplo.com.br"
                autofocus
              />
            </b-form-group>
            <b-form-group
              label="Senha"
              label-for="senhaInput"
            >
              <validation-provider
                #default="{ errors }"
                name="Senha"
                rules="required"
              >
                <senha-input
                  :id="'senhaInput'"
                  v-model="form.senha"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Confirmar senha"
              label-for="confirmarSenhaInput"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirmar senha"
                rules="required"
              >
                <senha-input
                  :id="'confirmarSenhaInput'"
                  v-model="form.confirmarSenha"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="abrirModal"
            >
              Registrar
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
    <b-modal
      ref="autenticar"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div
        class="brand-logo"
        style="text-align: center; margin-bottom: 1vw;margin-top: 1rem;"
      >
        <b-link>
          <b-img
            style="width: 70px;"
            :src="require('@/assets/images/logo/thumbnail.png')"
          />
        </b-link>
      </div>

      <h3 class="mb-1 text-center">
        Validação de Segurança! 🔑
      </h3>
      <div class="mb-2 text-center">
        Preencha o token de segurança para concluir seu cadastro.
      </div>

      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
      >
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="senha">Token
              <small
                style="float:right; margin-left: 210px;"
              >{{ horaServer }}</small>
            </label>
          </div>
          <senha-input
            :id="'senhaInput'"
            v-model="form.senhaTenant"
            :required="true"
            :validacao-nome="'Senha'"
          />
        </b-form-group>
        <b-button
          variant="primary"
          block
          class="mt-2"
          :disabled="invalid"
          @click="criarTenant"
        >
          Registrar
        </b-button>
      </b-form>
    </b-modal>
    <loading
      class="loading"
      :show-cancel-button="false"
      :is-loading="isLoading"
      :titulo-carregamento="'Aguarde!'"
      :descricao="'Criando usuário'"
    />
  </div>
</template>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.loading {
  .loading_titulo {
    display: flex;
      height: 12%;
  }

  .loading_descricao {
    display: flex;
      height: 14%;
  }
}
</style>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { cpfDocumento, cnpjDocumento } from '@pilar/plugins/p-validation/p-validation'
import api from './shared/services/api'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    SenhaInput: () => import('@pilar/components/senha-input/SenhaInput.vue'),
  },
  data() {
    return {
      cpfDocumento,
      cnpjDocumento,
      form: {
        cadastroFederal: '',
        login: '',
        senha: '',
        senhaTenant: '',
        tipoPlano: null,
        qtdContratada: null,
      },
      semPlano: { value: null, text: 'Selecione o plano empresarial', quantidade: null },
      planos: [],
      horaServer: '',
      esqueci: null,
      loginError: '',
      required,
      email,
      invalid: false,
      intervalID: null,
      isLoading: false,
      validacao: 'cpfDocumento',
    }
  },
  mounted() {
    this.updateDataServer()
    this.planos = [this.semPlano]
    this.getPlanos()
  },
  methods: {
    getPlanos() {
      api.getPlanos()
        .then(payload => {
          this.planos = [this.semPlano, ...payload.data]
        })
        .catch(err => {
          this.$erro(err)
        })
    },
    setDefaultQtdContatada(value) {
      this.form.qtdContratada = this.planos.filter(f => f.value === value)[0].quantidade
    },
    isCnpj() {
      return this.form.cadastroFederal
        .replace('___', '')
        .replace('__', '')
        .replace('_', '')
        .replace('-', '')
        .replace('/', '')
        .replace('.', '').length > 11
    },
    mascaraCadFederal() {
      if (this.isCnpj()) {
        this.validacao = 'cnpjDocumento'
        return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
      }

      this.validacao = 'cpfDocumento'
      return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    },
    abrirModal() {
      this.validarInfo()

      if (!this.intervalID) {
        this.intervalID = setInterval(() => {
          this.updateDataServer()
        }, 30000)
      }
    },
    validarInfo() {
      this.loginError = ''
      this.isLoading = true
      this.$refs
        .validacao
        .validate()
        .then(() => {
          api.getTenantValidate(this.form)
            .then(payload => {
              this.horaServer = payload.data
              this.$refs.autenticar.show()
              this.isLoading = false
            })
            .catch(err => {
              this.$erro(err.response?.data?.message || 'Obrigatório preencher todos os campos')
              this.isLoading = false
            })
        })
    },
    updateDataServer() {
      api.getDataServer()
        .then(payload => {
          this.horaServer = payload.data
        }).catch(() => { })
    },
    criarTenant() {
      this.loginError = ''
      this.isLoading = true
      this.$refs
        .validacao
        .validate()
        .then(() => {
          api.criarTenant(this.form)
            .then(() => {
              this.$router.push({ name: 'auth-login' })
              this.isLoading = false
            })
            .catch(err => {
              this.$erro(err.response?.data?.message)
              this.isLoading = false
            })
        })
    },
  },
}
</script>
