var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"width":"200px"},attrs:{"src":require('@/assets/images/logo/logo.png')}})],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Bem vindo! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Preencha os campos abaixo e comece a facilitar sua vida. ")]),_c('validation-observer',{ref:"validacao",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"cnpj","label":"CNPJ/CPF"}},[_c('validation-provider',{attrs:{"name":"CNPJ/CPF","rules":'required|' + _vm.validacao},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('masked-input',{staticClass:"form-control",class:{'is-invalid' : errors.length > 0},attrs:{"placeholder":"CNPJ ou CPF para cadastro","mask":_vm.mascaraCadFederal},model:{value:(_vm.form.cadastroFederal),callback:function ($$v) {_vm.$set(_vm.form, "cadastroFederal", $$v)},expression:"form.cadastroFederal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"empresa","label":"Nome da empresa"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"empresa","state":errors.length > 0 ? false:null,"placeholder":"Nome da sua empresa","autofocus":""},model:{value:(_vm.form.empresa),callback:function ($$v) {_vm.$set(_vm.form, "empresa", $$v)},expression:"form.empresa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"PlanosEmpresariais","label":"Plano empresarial"}},[_c('validation-provider',{attrs:{"name":"Plano empresarial","rules":"numberRequired"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.planos,"state":errors.length > 0 ? false : null,"autofocus":""},on:{"change":_vm.setDefaultQtdContatada},model:{value:(_vm.form.tipoPlano),callback:function ($$v) {_vm.$set(_vm.form, "tipoPlano", $$v)},expression:"form.tipoPlano"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"QtdCnpjs","label":"Quantidades de CNPJs/CPFs contratados"}},[_c('validation-provider',{attrs:{"name":"Quantidade","rules":"numberRequired"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"-","state":errors.length > 0 ? false : null,"autofocus":""},model:{value:(_vm.form.qtdContratada),callback:function ($$v) {_vm.$set(_vm.form, "qtdContratada", $$v)},expression:"form.qtdContratada"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"nomeusuario","label":"Nome do usuário"}},[_c('validation-provider',{attrs:{"name":"Usuário","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nomeusuario","state":errors.length > 0 ? false : null,"placeholder":"Nome completo","autofocus":""},model:{value:(_vm.form.nomeusuario),callback:function ($$v) {_vm.$set(_vm.form, "nomeusuario", $$v)},expression:"form.nomeusuario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"loginInput","label":"E-mail"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"loginInput","name":"login-email","state":errors.length > 0 ? false : null,"placeholder":"pessoa@exemplo.com.br","autofocus":""},on:{"input":function($event){_vm.form.loginSuporte = "acesso" + (_vm.form.login)}},model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", $$v)},expression:"form.login"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"loginSuporteInput","label":"E-mail Usuário Suporte Interno"}},[_c('b-form-input',{attrs:{"id":"loginSuporteInput","name":"login-email","disabled":"","placeholder":"suporteinterno@exemplo.com.br","autofocus":""},model:{value:(_vm.form.loginSuporte),callback:function ($$v) {_vm.$set(_vm.form, "loginSuporte", $$v)},expression:"form.loginSuporte"}})],1),_c('b-form-group',{attrs:{"label":"Senha","label-for":"senhaInput"}},[_c('validation-provider',{attrs:{"name":"Senha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('senha-input',{attrs:{"id":'senhaInput'},model:{value:(_vm.form.senha),callback:function ($$v) {_vm.$set(_vm.form, "senha", $$v)},expression:"form.senha"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirmar senha","label-for":"confirmarSenhaInput"}},[_c('validation-provider',{attrs:{"name":"Confirmar senha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('senha-input',{attrs:{"id":'confirmarSenhaInput'},model:{value:(_vm.form.confirmarSenha),callback:function ($$v) {_vm.$set(_vm.form, "confirmarSenha", $$v)},expression:"form.confirmarSenha"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid},on:{"click":_vm.abrirModal}},[_vm._v(" Registrar ")])],1)]}}])})],1)],1),_c('b-modal',{ref:"autenticar",attrs:{"hide-footer":"","hide-header":"","centered":"","size":"sm"}},[_c('div',{staticClass:"brand-logo",staticStyle:{"text-align":"center","margin-bottom":"1vw","margin-top":"1rem"}},[_c('b-link',[_c('b-img',{staticStyle:{"width":"70px"},attrs:{"src":require('@/assets/images/logo/thumbnail.png')}})],1)],1),_c('h3',{staticClass:"mb-1 text-center"},[_vm._v(" Validação de Segurança! 🔑 ")]),_c('div',{staticClass:"mb-2 text-center"},[_vm._v(" Preencha o token de segurança para concluir seu cadastro. ")]),_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"senha"}},[_vm._v("Token "),_c('small',{staticStyle:{"float":"right","margin-left":"210px"}},[_vm._v(_vm._s(_vm.horaServer))])])]),_c('senha-input',{attrs:{"id":'senhaInput',"required":true,"validacao-nome":'Senha'},model:{value:(_vm.form.senhaTenant),callback:function ($$v) {_vm.$set(_vm.form, "senhaTenant", $$v)},expression:"form.senhaTenant"}})],1),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":"","disabled":_vm.invalid},on:{"click":_vm.criarTenant}},[_vm._v(" Registrar ")])],1)],1),_c('loading',{staticClass:"loading",attrs:{"show-cancel-button":false,"is-loading":_vm.isLoading,"titulo-carregamento":'Aguarde!',"descricao":'Criando usuário'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }